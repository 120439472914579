import { Image, styled } from '@obvio/app'
import { RichText } from '@obvio/template'
import { Button, Stack } from '@obvio/ui'

import type { PopupData } from '@obvio/template'
import type { ReactElement } from 'react'

const ImageWrap = styled.div`
  height: 14rem;
  width: 100%;
`

export function PopupContent({
  cta,
  ctaText,
  image,
  description,
}: PopupData): ReactElement {
  return (
    <Stack kind="vertical">
      {image ? (
        <ImageWrap>
          <Image img={image} />
        </ImageWrap>
      ) : null}
      <div>
        <RichText value={description ?? ''} />
      </div>
      {cta ? (
        <Button kind="ghost" href={cta}>
          {ctaText ?? 'EXPLORE'}
        </Button>
      ) : null}
    </Stack>
  )
}
