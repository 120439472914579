import { mobile, styled, Text as UiText } from '@obvio/app'
import { useUi } from '@obvio/template'
import { motion, useMotionValue, useSpring, useTransform } from 'framer-motion'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import type { AllowUndefinedForOptional } from '@obvio/utils'
import type { Variants } from 'framer-motion'
import type { ReactElement } from 'react'

const Dot = styled.div`
  border-radius: 9999px;
  position: fixed;
  ${(theme) => theme.flexCenter}
  // TODO: z-index higher than modal
  z-index: 500000000;
  font-size: 13px;
  overflow: hidden;
  pointer-events: none;
  @media ${mobile} {
    display: none !important;
  }
`

const CursorText = styled(UiText) <{ $black: boolean }>`
  pointer-events: none;
  color: ${(_, { $black }) => ($black ? '#ffffff' : '#000000')};
`

const MotionDot = motion(Dot)

type Kind = 'white' | 'black'

type ContextValue = {
  value: string | null
  kind: Kind
  navTransparent: boolean
  setValue(arg: string | null): void
  setKind(kind: Kind): void
  setNavTransparent(arg: boolean): void
}

const Context = createContext<ContextValue>({
  value: null,
  kind: 'white',
  navTransparent: false,
  setValue: () => null,
  setKind: () => null,
  setNavTransparent: () => null,
})

export function useCursorContext(): ContextValue {
  const value = useContext(Context)
  return useMemo(() => value, [value])
}

type CursorContextProps = {
  children: ReactElement | ReactElement[]
}

export function CursorContext({ children }: CursorContextProps): ReactElement {
  const [text, setText] = useState<string | null>(null)
  const [kind, setKind] = useState<Kind>('white')
  const [navTransparent, setNavTransparent] = useState<boolean>(false)

  const value: ContextValue = useMemo(
    () => ({
      value: text,
      kind,
      setKind,
      setValue: setText,
      navTransparent,
      setNavTransparent,
    }),
    [kind, navTransparent, text],
  )
  return <Context.Provider value={value}>{children}</Context.Provider>
}

type CursorProps = AllowUndefinedForOptional<{
  kind?: Kind
}>

export function Cursor({
  kind: defaultKind = 'white',
}: CursorProps): ReactElement {
  const { value, kind, setKind, setValue } = useCursorContext()
  const [{ menuPanelOpen }] = useUi('menuPanelOpen')

  const cursorX = useMotionValue(-100)
  const cursorY = useMotionValue(-100)

  useEffect(() => {
    cursorX.set(window.innerWidth / 2)
    cursorY.set(window.innerHeight / 2)
    setKind(defaultKind)
    setValue(null)
  }, [cursorX, cursorY, defaultKind, setKind, setValue])

  const springConfig = useMemo(() => ({ bounce: 0.1, mass: 0.1 }), [])

  const cursorXSpring = useSpring(cursorX, springConfig)
  const cursorYSpring = useSpring(cursorY, springConfig)

  const transform = useTransform(
    [cursorXSpring, cursorYSpring],
    ([x, y]) => `translateX(${x as number}px) translateY(${y as number}px)`,
  ) as unknown as string

  const backgroundColor = useMemo(
    () => (kind === 'white' || menuPanelOpen ? '#ffffff' : '#000000'),
    [kind, menuPanelOpen],
  )

  const variants: Variants = useMemo(
    () => ({
      default: {
        height: 16,
        width: 16,
        backgroundColor,
        transition: {
          type: 'spring',
          bounce: 0.1,
          mass: 0.1,
        },
      },
      content: {
        height: 115,
        width: 115,
        backgroundColor,
        transition: {
          type: 'spring',
          bounce: 0.1,
          mass: 0.1,
        },
      },
    }),
    [backgroundColor],
  )

  const listener = useCallback(
    (ev: MouseEvent) => {
      const { clientX, clientY } = ev

      cursorX.set(clientX - (value ? 50 : 8))
      cursorY.set(clientY - (value ? 50 : 8))
    },
    [cursorX, cursorY, value],
  )

  useEffect(() => {
    window.addEventListener('mousemove', listener)
    return () => window.removeEventListener('mousemove', listener)
  }, [listener])

  return (
    <MotionDot
      style={{ transform }}
      variants={variants}
      animate={value ? 'content' : 'default'}
    >
      <CursorText tag="span" $black={backgroundColor === '#000000'}>
        {value}
      </CursorText>
    </MotionDot>
  )
}
