/* eslint-disable sonarjs/no-duplicate-string */
import 'react-whatsapp-widget/dist/index.css'
import 'keen-slider/keen-slider.min.css'
import '../global.css'

import { app, createStore } from '@obvio/app'
import { Popup } from '@obvio/template'

import { CursorContext } from '@/components/Cursor'
import { PopupContent } from '@/components/PopupContent'

export const globalStore = createStore(
  {
    MEETING: (_, newState = true) => ({
      meeting: newState,
    }),
  },
  { meeting: false },
)

export default app({
  globalStoreHook: globalStore,
  contextPaths: {
    event: '/event',
    ecommerce: '/store',
    'ecommerce.product': '/store/product',
    'ecommerce.category': '/store/category',
    'ecommerce.search': '/store/search',
    'ecommerce.checkout': '/store/checkout',
    'ecommerce.userPanel': '/sklep/panel',
    'ecommerce.userPanel.wishlist': '/store/panel/favourite',
    'ecommerce.userPanel.settings': '/store/panel/settings',
    'ecommerce.userPanel.addresses': '/store/panel/addresses',
    signIn: '/signin',
    signUp: '/singup',
    privacyPolicy: '/',
    tos: '/regulamin',
    forgotPassword: '/reset-password',
    article: '/blog',
  },
  seoData: {
    title: 'Fijewski Gallery',
    canonical: `https://fijewski.com`,
    description:
      'Z siedzibą w Warszawie, Konstancinie, Zurychu i Miami  Fijewski Gallery tylko ostatnio przyciągnęła na swoje wydarzenia ponad 14 000 kolekcjonerów, stając się ważną prywatną instytucją kultury. Podejmuje tematykę łączenia zjawisk artystycznych powstałych w powojennej Polsce z dorobkiem twórczym powstałym na emigracji.',
    openGraph: {
      type: 'website',
      title: 'Fijewski Gallery',
      description:
        'Z siedzibą w Warszawie, Konstancinie, Zurychu i Miami  Fijewski Gallery tylko ostatnio przyciągnęła na swoje wydarzenia ponad 14 000 kolekcjonerów, stając się ważną prywatną instytucją kultury. Podejmuje tematykę łączenia zjawisk artystycznych powstałych w powojennej Polsce z dorobkiem twórczym powstałym na emigracji.',
      images: [
        {
          url: `https://www.fijewski.com/static/images/fijewski.png`,
          width: 1200,
          height: 630,
          type: `image/png`,
          alt: 'Fijewski Gallery',
        },
      ],
    },
  },
  enhance: (Component) =>
    function Comp(props) {
      return (
        <CursorContext>
          <Component {...props} />
          <Popup>{(data) => <PopupContent {...data} />}</Popup>
        </CursorContext>
      )
    },
})
